@font-face {
  font-family: FilsonProBold;
  src: url(./fonts/filson-pro/FilsonProBold.otf);
  font-display: swap;
}
@font-face {
  font-family: FilsonProLight;
  src: url(./fonts/filson-pro/FilsonProLight.otf);
  font-display: swap;
}
@font-face {
  font-family: FilsonProRegular;
  src: url(./fonts/filson-pro/FilsonProRegular.otf);
  font-display: swap;
}

html, body {
  font-family: 'FilsonProRegular', sans-serif !important;
}
a {
  text-decoration: none !important;
  color: inherit !important;
}
ul li {
  list-style-position: outside;
}

.li-blog {
  color: #000;
  list-style: circle;
  cursor: pointer;
}
.li-blog:hover {
  color: #000;
  list-style: disc;
}
.li-blog-selected {
  color: #00a6e7;
  list-style: disc;
  cursor: pointer;
}
.li-blog-selected :hover {
  color: #00a6e7;
  list-style: disc;
}

.carousel-control-prev-icon {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAACSklEQVR4nO2awU4TURSGv5WDCw0mCu5k6QJXuNSdgZhoZFngGYiEt0BcaDQxbn0EtVHegBjXQgsrkBKMuBYhHHOTQ9KUmWk7c+6dO4Q/+ZM2bc+cP/+d23POHbjExcUNYBZYBZrAJvAHOFK61xv6mfvOM2CUSJAAC8AacALIkHS/+QLMa6zguAosA3sFks/iT2AJGAkl4jGwbSigl1vAjE8BzvrXHgX08oM6b4px4HtAEaL8BoxZiZhQu6UitjWHUril26hUzG3gdlERIxUtJ8lZZoW26HcRJC89fFNki5VIOT2oCGdfK4KEJYNbg/5pLntK4ACYAh4YxHo+iBt7nkRM6jXuG8Tb7XfjL3gWManvLeLO5QlZMxbxC7inse8C+4axm3n9xEkNnBDlMXA9TchsTZyQLj5JE/KyJk5IF1+kCWnWyAlRfkwT0q6RE6J0Be05HNbICem67jkclQg4FdgJUf690EIODZdWp8ql1arhzb4RavvtVLH9rhoFD+nMSugSpROyRBmtWdH4L6todPhqfDGfznwmB/Me7PflTCNPSKJT8dhb3R3gCn2w5EHImRgn4qFBrMV+Is5ciWFMKhlsDzNxnIkgYUnhKfCIIfE2gsSlh68ogEQHxxIJ1we5wbNwM6JjhXFKYsKgDS7DFnAHI4xVtMzW9bDJy2HoaSAR732fvU97XmqbRbbYokh0tL9rKMCVHYtVPQGR6FS8qbPYYZM/1iq2UWZrtYbrDZ7qGPOT9tO/ux6qca9/aHu6ok3RNfMsLkEc+A+TWzlJWK35FAAAAABJRU5ErkJggg==") !important;
 }
 .carousel-control-next-icon {
   background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAACSElEQVR4nO2az0ocQRDGf6eMOSQoGM0tHnPQk97MMSiBhHg0+gwS8S38c1ASCLnmERIX4xtIyDmuq6eoCYp61hgsaShBhpnZ3ZmumR7xgw8Gdre7Pr7u3qrqgXvcXfQBU8Ay0ACawBlwoXTP2/qZ+85boJdAEAGzwCbwH5Au6X6zAczoWKXjIbAAHOYIPo0HwDzQU5aIV8CeRwFx7gKTlgKc9WuGAuL8os57xSDws0QRovwBDPgSMaR2S0VsaQyF8ESPUamYe8DTvCJ6KlpOkrHMch3RnwIIXmL8kOeIlUA50akIZ99OAAFLCnc7/dNc8DDZODAGHBmJed+JGz7SjlEdb9hIzH67jT/raaIjFWEp5l2WkE2PEx0DIzruc+CPZyGNrHoiTypelTOXwOMkIVMG9ls78zpJyIqREEtnlpKENAyFWDnzNUlIy1iIhTPNJCGnJQjx7cxxkpCLkoTEnRkrMM75nRZyWtHS+ut7ae3UcLNvh3D8FnFCso7f5Ro5IcrFKlMUH05IVorSW7Ok8V9a0ujwvSZOCLBOBmZq4IQop7OERNoVD73U/Q08oA3mPUz0wrj5MNdOxI0rIbRJJYWtbjqOkwEELAm8Al7SJT4GELjEuEoORNo4lkC41ckGT0N/QNcKgxTEUEllcBpdVv4MTxioaJlt6WWTyWXoVUkiPlvfvU8YL7VmniM2LyJt7e97TjvmqnoDItKueEN7sd0Gf6lZ7HSRo9U3XG3wRtuY37SePrn1Uo17/qXl6aIWRY+8R3EPwsA1oBk5SAaWVBQAAAAASUVORK5CYII=") !important;
 }
.slick-prev:before, .slick-next:before {
  color: #00a6e7 !important;
}
.__rcs-handle-line, .__rcs-handle-button {
  position: relative;
  left: -10px;
}
.modal-content {
  border-radius: 40px !important;
  background-color: transparent !important;
  border: 0 !important;
}
.modal-header {
  border: 0 !important;
}
.btn-close {
  opacity: 1 !important;
  filter: invert(100%);
}

.annotationLayer {
  display: none !important;
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  isolation: isolate;
    border-color: transparent !important;
}
.nav-tabs {
  border-bottom: 0 !important;
}
.form-control {
  font-family: 'FilsonProRegular', sans-serif !important;
  font-size: 15px !important;
  color: #000 !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom: 1px solid #000 !important;
  border-radius: 0 !important;
  margin-bottom: 15px !important;
  background-color: transparent !important;
}
.form-label {
  font-size: 18px !important;
}

.grecaptcha-badge {
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  left: 4px !important;
}
.grecaptcha-badge:hover {
  width: 256px !important;
}
.styles-module_whatsappButton__tVits {
  right: 0rem !important;
  border-radius: 10px 0 0 10px !important;
  background-color: #5CA2D1 !important;
}
.styles-module_whatsappButton__tVits:after {
  animation: none !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.nav-link-comp{
  font-size: 15px!important;
  font-weight: normal !important;
}
.nav-link {
  color: #fff !important;
  font-size: 25px;
  font-weight: 500;
}
.nav-item:first-child {
  background-color:#00a6e7;
  color: #000 !important;
  border-radius: 25px 0 0 25px;
}
.nav-item:last-child {
  background-color:#00a6e7;
  color: #000 !important;
  border-radius: 0 25px 25px 0;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  background-color: #dadad9!important;
  border-radius: 25px;
  color: #000!important;
  font-size: 25px!important;
  font-weight: 500;
}

.container-no-format {
  max-width: 100% !important;
  padding: 0 !important;
}
.col-md-custom {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  display: flex;
}
.article-item {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}


.video{
  color:  #0cacec!important;
}
.Menu-fondo{
  color:#000000;
}
.Menu-letras{
  height: 60px; 
}
.nav-link{
  color: #000000 !important;
  display: block;
  padding: 15px 0;
  text-decoration: none;
}
.clearfixe{  height: 60px;}


.social-media{
  width: 20px;
  height: 20px;
  margin: 0 5px;
  text-align: center;
  color:  #0cacec!important;
}
.line{
  border-right: 1px solid #0cacec !important;
}
.phone{
  margin: 0 5px;
  text-align: left;
  color:  #0cacec!important;
  font-size: 12px;
}
.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}
.Menu-letras{
  height: 20px; 
}
.clearfixe{  
  height: 20px;
}
.clearfixe-body{  
  height: 50px;
}

.banner-title-ques{
font-family: 'FilsonProRegular', sans-serif;
font-size: 80px;
color:#000000;
text-align: left;
line-height: 80px;
}
.banner-title-ques-2{
  font-family: 'FilsonProBold', sans-serif;
  font-size: 80px;
  color:  #0cacec!important;
  line-height: 80px;
  text-align:left;
}
.banner-title-equi-2{
  font-family: 'FilsonProRegular', sans-serif;
  font-size: 80px;
  color: #344464!important;
  line-height: 80px;
  text-align:left;
}
.banner-title-address-2{
  font-family: 'FilsonProBold', sans-serif;
  font-size: 65px;
  color: #344464!important;
  line-height: 95px;

}
.banner-text-container {
  max-width: 100%;
}
.equipo-trabajo-main {
  font-family: 'FilsonProBold', sans-serif;
  font-size: 30px;
  text-align: center;
  color:#000;
  font-weight: bold;
}
.equipo-trabajo{
  font-size: 20px;
  text-align: center;
  color:#000;
  font-weight: bold;
}

.equipo-trabajo-principal{
  font-size: 25px;
  text-align: center;
  color:#000;
}
.equipo-trabajo-principal-text{
  font-size: 15px;
  font-weight: 400;
}
.img-colab{
  width: 100%;
  max-width: 300px;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
}
.text-colab{
  font-family: 'FilsonProLight', sans-serif;
 font-size: 22px;
 font-weight: 200;
}
.img-carr{
  width: 100%;
  padding: 10px;
}
.banner-title {
  font-size: 75px;
  font-weight: 900;
  text-align: center;
  font-style: italic;
  color: #fff;
}
.banner-desc {
  font-size: 25px;
  color: #070707;
  text-align: center;
  font-weight: 400;
  background-image: url(./img/Rect.webp);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 20px;
  background-size: 50%;
}
.banner-desc-ques {
  font-family: 'FilsonProRegular', sans-serif;
  font-size: 16px;
  color: #070707;
  text-align: left;
  font-weight: 400;
  padding: 20px 20px 0 20px;
  background-size: 50%;
}
.banner-bg-ques{
  size: 50%;
  align-items: left;
}
.banner-container {
  padding: 200px 0 200px 0;
  text-align: left;
  width: 100%;
}
.banner-container-ques {
  text-align: right;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}
.container-default {
  padding-top: 50px;
  padding-bottom: 50px;
}
.logo{
  padding: 5px;
}
.h6{
  font-size: 10px;
}
.oblique-button {
  font-family: 'FilsonProRegular', sans-serif !important;
  position: relative;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 30px;
  background-color: #000;
  border: none;
  font-family: Arial, sans-serif;
  font-size: 16px;
  text-decoration: none;
  color: #ffffff !important; /* Cambia el color del texto a blanco */
  overflow: hidden;
  transition: transform 0.3s;
}



.oblique-button:hover {
  transform: translateY(-3px);
  color:#fff;
}

.oblique-button:hover:before {
  transform: rotate(-10deg) scale(1.2);
}

.banner-bg-exp{
  background-color:  #f0ecec;
  padding: 50px;

}
.banner-title-exp{
  font-family: 'FilsonProBold', sans-serif;
  font-size: 80px;
  color:  #000000 ;
  line-height: 95px;
  }

.banner-img{
    width: 100%;
    padding: 10px;
}
.banner-title-vis{
  font-family: 'FilsonProBold', sans-serif;
  font-size: 80px;
  color:  #000000 ;
  }

.banner-bg-vis
{
  background-color:  #ffffff;
}
.doc-style {
  width: 85%;
  text-align: center;
}
.banner-title-bg{
  font-family: 'FilsonProRegular', sans-serif;
  font-size: 70px;
  color: #000!important;
  line-height: 70px;
  text-align:center;
  font-weight: 600;
}
.banner-title-small{
  font-family: 'FilsonProRegular', sans-serif;
  font-size: 40px;
  color: #000!important;
  line-height: 70px;
  text-align:center;
  font-weight: 600;
}
.banner-title-sm{
  font-family: 'FilsonProRegular', sans-serif;
  font-size: 70px;
  color: #000!important;
  line-height: 70px;
}
.banner-title-sm-bold {
  font-family: 'FilsonProBold', sans-serif;
  font-size: 70px;
  color: #000!important;
  line-height: 70px;
}
.delgado{
  font-family: 'FilsonProLight', sans-serif;
}

.card-title {
  font-family: 'FilsonProBold', sans-serif !important;
  font-size: 18px !important;
}
.card-text {
  font-family: 'FilsonProRegular', sans-serif !important;
  font-size: 15px !important;
}
.blog-list {
  font-family: 'FilsonProBold', sans-serif !important;
  font-size: 18px !important;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #000;
}

.img-test{
  width: 70%!important;
  background-color:  #e8ecfc;
  
}
.flecha-div {
  position: absolute;
  padding-top: 20px;
  right: -30px;
  z-index: 99;
}
.flecha-img{
  width: 200px;
}
.carousel-container {
 background-color: #0cacec;
 border-radius: 800px;
}
.section-footer{
  font-family: 'FilsonProRegular', sans-serif !important;
  font-size: 15px;
  color: #fff;
  height: 60px;
  background-color: #000000;
  text-align: center;
  vertical-align: middle;
}
.social-media-f{
  width: 20px;
  height: 20px;
  margin: 0 5px;
  text-align: center;
  color:  #0cacec!important;
}

.tab-container {
  
  margin: 0 auto;
}

.tab-pill {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  position: relative;
}
.testimonio{
  background-color: #e1effa!important ;
  border-radius: 30px !important;
  padding: 25px;
  border: 0 !important;
  min-height: 570px;
}
.tab-button-new{
  background-color: #0cacec;
  width: 250px!important;
  height: 75px!important;
}
.tab-button {
  width: 250px!important;
  height: 75px!important;
  padding: 10px 20px;
  position: absolute;
  background-color:  #dbdcdc;
  border: none;
  border-radius: 35px;
  cursor: pointer;
  font-size: 35px;
  font-weight: bold;
  vertical-align: middle;
  z-index: 2;
}
.tab-button-fus{
  position: relative;
}

.tab-button.active {
  background-color: #0cacec;
}

.tab-content .tab-pane {
  display: none;
}
.nav-tabs .nav-link {
  border-radius: 25px;
  height: 75px;
  width: 250px;
}
.tab-content .tab-pane.active {
  display: block;
}
.text-gust{
  font-family: 'FilsonProRegular', sans-serif !important;
  font-size: 65px;
  line-height: 65px;
}
.text-gust-2{
  font-family: 'FilsonProBold', sans-serif !important;
  font-size: 65px;
  line-height: 65px;
}
.more-text{
 float: right;
 background-color:  #5ca2d1!important;
 color: #fff !important;
 width: 135px!important;
 font-weight: 400!important;
 font-size: 15px!important;
 border-radius: 25px!important;
 right: 140;
}
.more-text:hover{
  background-color:  #000!important;
}

.phones-contact{ 
  height: 15px;
  width: 15px!important;  
}
.phones-contact-text{ 
  font-family: 'FilsonProRegular', sans-serif !important;
  font-size: 15px !important;
  line-height: 20px;
}
.phones-contact-text-2 {
  font-family: 'FilsonProRegular', sans-serif !important;
  font-size: 18px !important;
}

.text-address{
  font-family: 'FilsonProRegular', sans-serif !important;
  font-size: 15px;
}

.col-md-custom-comp{
  position: relative;
  width: 100%;
  padding: 30px !important;
}
.comp-title{
  font-family: 'FilsonProBold', sans-serif !important;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
.comp-img{
  max-width: 130px;
}
.comp-conte{
  font-family: 'FilsonProRegular', sans-serif !important;
  font-size: 15px;
  text-align: center;
}
.comp-background{
  background-color: #ebebea;
}
.img-serv-1{
  width: 100%;
}
.img-serv-2{
  width: 105%;
  padding: 2px;
  padding-left: 0;
}
.img-serv-3{
  width: 101%;
  padding: 2px;
  padding-left: 0;
}
.banner-descrip-orto{
  font-family: 'FilsonProRegular', sans-serif !important;
  font-size: 20px;
  text-align: center;
  max-width: 800px !important;
}
.banner-bg-orto{
  background-color:#e5f1fa;
  border-radius: 25px;
  align-items: center!important;
}
.p-ortodoncia {
  padding: 60px !important;
}
.p-hope {
  padding-left: 5% !important;
  padding-right: 5% !important;
}
.font-orto{
  font-size: 25px;
  text-align: center;
  line-height: 25px;
  
}
.circle {
  position: relative;
  top: -2px;
  width: 15px;
}
.ortodoncia-img {
  width: 70%;
}
.font-blond-small{
  font-family: 'FilsonProBold', sans-serif !important;
  font-size: 18px;
}
.font-blond{
  font-family: 'FilsonProBold', sans-serif !important;
  font-size: 20px;
}
.font-desc{
  font-family: 'FilsonProLight', sans-serif !important;
  font-size: 14px;
  line-height: 19px;
}
.font-desc-regular{
  font-family: 'FilsonProRegular', sans-serif !important;
  font-size: 16px;
}
.padding-img{
  padding: 25px!important;
}

.boton-blue{
  border-radius: 50%;
  background-color:#5ca2d1;
  color: #ebebea;
  width: 30px;
  text-align: center;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px; 
}
.banner-bg-coti{
  background-color:#e5f1fa;
  border-radius: 30px;
  padding: 80px;
}
.column-fina{
  padding: 30px;
  font-family: 'FilsonProRegular', sans-serif !important;
}
.line-fina{
  border-right:  solid #000 !important;
  padding-right: 24px;
}
.title-finan{
  text-align: center!important ;
  font-size: 30px;
}
.cita-fina{
  border-radius: 35px;
  background-color: #fff;
  text-align: center;
  color: #5ca2d1;
  padding-top: 20px;
  padding-bottom: 20px;
  vertical-align: middle!important;
}
.img-100 {
  width: 100%;
}
.img-80 {
  width: 80%;
}
.img-alin{
  width:  100%;
}
.img-alin-absolute{
  right: 0;
  width:  100%;
}
.font-aling-center{
  text-align: center!important;
}
.format-bg-ortoped{
  background-color: #ebebea;
  border-radius: 25px;
  font-size: 15px;
  text-align: center;
}
.formt-hada{
  font-size: 25px;
  color: #60a4d1;
  text-align: center;
  font-weight: 650!important;
  padding: 25px;
}
.format-ortopediatria{
  padding: 10px;
  text-align: center;
  
}
.format-estetica-title{
  font-size: 30px;
  border-radius: 35px;
  text-align: center;
  background-color: #ebebea;
}
.format-endodo{
  font-size: 30px;
  text-align: left;

}
.format-footer-endodo{
  background-color: #ebebea;
  font-weight: bold;
}
.format-implant{
  border-radius: 35px;
  background-color: #e5f1fa;
  padding: 21px;
}
.contact-back {
  background-color: #D7EAF8;
  border-radius: 12%;
}
.contact-img {
  border-radius: 12%;
}
.one {
  list-style: circle;
  font-size: 25px;
}
.teeth-back {
  background: url(./img/teeth.webp);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  padding: 120px 50px;
  text-align: center;
}
.circle {
  width: 18px;
  height: 18px;
  line-height: 18px;
  border-radius: 50%;
  font-family: 'FilsonProLight', sans-serif;
  font-size: 12px;
  color: #000;
  text-align: center;
  display: inline-block;
  border: 1px solid #5CA2D1;
}
.circle:hover {
  background: #5CA2D1;
}
.banner-orto-img {
  width: 80%;
}
.player-style {
  width: 100% !important;
  height: calc( 100vw / 1.778 ) !important;
}
.player-ad-style {
  width: 100% !important;
  height: auto !important;
}
.close-btn {
  color: #000;
  text-align: right;
  font-size: 25px;
  cursor: pointer;
}
.photo-class {
  transition: all .2s ease-in-out; 
}
.photo-class:hover { 
  transform: scale(0.98); 
}
.image-100 {
  width: 100%;
}
.card-blog {
  transition: all .2s ease-in-out; 
  cursor: pointer;
}
.card-blog:hover {
  transform: scale(0.98); 
} 
.no-show {
  visibility: hidden;
}
.doctor-container {
  background-color: #fff;
  border-radius: 25px;
  padding: 20px;
}
.hada {
  width: 100%;
  background-image: url(./img/hada.webp);
  background-position: center;
  background-size: cover;
}
.hada:hover {
  background-image: url(./img/hada2.webp);
} 
.diente {
  width: 100%;
  background-image: url(./img/diente.webp);
  background-position: center;
  background-size: cover;
}
.diente:hover {
  background-image: url(./img/dienteb.webp);
}
.service-text {
  text-align: center;
  font-family: 'FilsonProBold', sans-serif;
  font-size: 18px;
  padding-top: 15px;
}
.service-text-small {
  text-align: center;
  font-family: 'FilsonProLight', sans-serif;
  font-size: 13px;
}
.air-item {
  position: relative;
}
.doctores-img {
  width: 100%;
  padding: 10px;
  border-radius: 25px;
}

@media (min-width: 1500px) {
  .teeth-back {
    padding: 100px 100px;
  }
}

@media (max-width: 460px) {
  .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    background-color: #dadad9!important;
    border-radius: 25px;
    color: #000!important;
    font-size: 20px!important;
    font-weight: 500;
  }
  .nav-tabs .nav-link {
    border-radius: 25px;
    height: 59px;
    width: 132px;
  }

  .banner-title-ques, .banner-title-ques-2, .banner-title-exp, .banner-title-equi-2, .banner-title-sm, .banner-title-bg, .banner-title-vis, .banner-title-sm-bold, .banner-title-address-2, .text-gust, .text-gust-2 {
    font-size: 50px;
    line-height: 60px;
    text-align: center !important;
  }
  .banner-title-bg-mobile {
    font-size: 40px;
    text-align: center !important;
  }
  .banner-title-small {
    font-size: 20px;
  }
  .banner-desc-ques, .equipo-trabajo-main {
    text-align: center !important;
  }
  .equipo-trabajo {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .flecha-div {
    display: none;
  }
  .player-style {
    padding: 0 !important;
  }
  .section-footer {
    height: auto;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .col-md-custom-comp {
    padding: 15px !important;
  }
  .p-ortodoncia, .banner-bg-coti {
    padding: 30px !important;
  }
  .img-alin-absolute {
    position: relative;
  }
  .line-fina {
    border-right: 0 !important;
    padding-right: 0 !important;
  }
  .column-fina {
    text-align: center;
  }
  .teeth-back {
    padding: 100px 50px;
  }
  .img-100 {
    padding: 30px
  }
  .font-orto {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 20px;
  }
}